@media only screen and (max-width: 600px) {
    .container {
        max-width: 1050px;
        width: 90%;
        margin: auto;
      }
      
      .navbar {
        width: 100%;
        box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
      }
      
      .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
      }
      
      .navbar .menu-items {
        display: flex;
      }
      
      .navbar .nav-container li {
        list-style: none;
      }
      
      .navbar .nav-container a {
        margin-top: 1rem;
        text-decoration: none;
        color: black;
        font-weight: 400;
        /* font-size: 2rem; */
        /* padding: 0.7rem; */
    }
      
      .navbar .nav-container a:hover{
          font-weight: bolder;
      }
      
      .nav-container {
        /* display: block; */
        position: relative;
        height: 60px;
      }
      
      .nav-container .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 40px;
        top: 20px;
        margin-left: 1rem;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
      }
      
      .nav-container .hamburger-lines {
        display: block;
        height: 30px;
        width: 35px;
        position: absolute;
        top: 17px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      
      .nav-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #0e2431;
      }
      
      .nav-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
      }
      
      .nav-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
      }
      
      .nav-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
      }
      
      .navbar .menu-items {
        border-radius: 0px 0px 30px 0px; 
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-left: -70px;
        margin-top: 8rem;
        padding-top: 0;
        padding: 3.5rem 2rem;
        margin-top: 10rem;
        width: auto;
        height: 10vh;
        transition: transform 0.5s ease-in-out;
        text-align: center;
        background-color: white;
      }
      
      .navbar .menu-items li {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 500;
      }
      
      .logo {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 1.2rem;
        color: #0e2431;
      }
      
      .nav-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
      }
      
      .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(45deg);
      }
      
      .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: scaleY(0);
      }
      
      .nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
        transform: rotate(-45deg);
      }
      
      .nav-container input[type="checkbox"]:checked ~ .logo{
        display: none;
      }
    }
    .checkbox{
        display: none;
    }
    .nav-container{
        display: flex;
        justify-content: space-between;
    }
    .hamburger-lines{
        display: none;
    }