.how{
    padding: 6rem;
    font-size: 16px;
    margin-top: 2rem;
    line-height: 2.5;
    display: block;
   text-align: center;
}
.work-arrow{
    margin-top: 0.5rem;
}
/* .work-nav{
    background: radial-gradient(50% 50% at 50% 50%, #D1D6DA 0.01%, #B5BEC4 1%, #FFFFFF 2%, #CAF4FF 100%);    
    padding: 1rem 5rem;
} */
@media only screen and (max-width: 600px){
.how{
    padding: 1rem;
}
}