/* *{
    outline: 1px solid red;
} */

body{
    background: radial-gradient(50% 50% at 50% 50%, #D1D6DA 0.01%, #B5BEC4 0.02%, #FFFFFF 40.62%, #CAF4FF 100%);
padding: 1rem 5rem;
/* font-family: ; */
scroll-behavior: smooth;
}

.menu-header{display: flex;
justify-content: space-between;
}
.menu-options{
    display: flex;
    gap: 3rem;
    align-items: center;
}
.hamburger {
    display: none;
}

.burger {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

/* -----section2 */
.powering-section{
display: flex;
gap: 4rem;
margin-top: 4rem;
margin-bottom: 4rem;
}
.powering-header{
    font-size: 65px;
    line-height: 1.2;
    margin-left: 5.5rem;
}
.scroll-down{
    transform: rotate(270deg);
    margin-top: 25rem;
    width: 7rem;
    font-size: 16px;
    margin-bottom: 2rem;
}
.scroll{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.circle{
    padding-left: 3rem;
}
.powering-para{
padding-left: 6rem;
padding-right: 6rem;
font-size: 18px;
line-height: 1.5rem;
color: #5B5B5B;
}


/* ---transactions */
a{
    text-decoration: none;
    display: flex;
    gap: 1rem;
    color: black;
}
.learn{
    margin: 0;
}
.transactions{
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
}
h2{
    /* font-size: 80px; */
}
.tap-transaction{
    font-size: 30px;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}
.tap-text{
    padding-right: 20rem;
    font-size: 18px;
    line-height: 2rem;
    color: #5B5B5B;
}
.won{
    font-size: 65px;
    line-height: 1.2;
    padding-right: 10rem;
}

/* ---section3 */
.more{
    font-size: 65px;
    margin-block-end: 0.3em;
}
.wireless{
    display: flex;
    flex-direction: column;
    justify-content: center;align-items: center;
}
.text-wireless{
    padding-left: 25rem;
    padding-right: 25rem;
    text-align: center;
    line-height: 1.5rem;
    color: #5B5B5B;padding-bottom: 2rem;
    font-size: 18px;
}

/* --transaction payment */
.totap{
    width: 30rem;
    margin-block-end: 0em;
    font-size: 65px;
    line-height: 1.2;
}
.debit{
    padding-left: 13rem;
/* width: 30rem; */
}
.tapto{
    padding-right: 10rem;
    font-size: 18px;
    line-height: 2rem;
    margin-block-start: 0em;
    color: #5B5B5B;
}
/* -----black card */
.black-card{
   display: flex;
   justify-content: center;
   overflow: hidden;
}
.black-card1{
    width: 100% !important;
    height: 100% !important;
}
/* :root {
    --main-width: 60vw;
  }
  
  main {
    height: 100vh;
    max-width: var(--main-width);
  
   
  
  } */
  
  iframe {
    width: 100%;
    height: 40rem;
    border-radius: 20px;
  }

/* ----encrypted transaction */

.tap-transaction2{
    font-size: 65px;
    line-height: 1.2;
    margin-block-end: 0.2em;
}
.tap-text2{
    margin-block-start: 0em;
    padding-right: 20rem;
    font-size: 18px;
    line-height: 2rem;
    color: #5B5B5B;
}



/* -----request */
.request{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
   
}
.join{
       font-size: 16px; 
       color: #5B5B5B;
}
.info{
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 65px;
}
.wonda-text{
    padding-left: 25rem;
   padding-right: 25rem;
   text-align: center;
   color: #5B5B5B;
   font-size: 16px;
}
.butt{
    border-radius: 30px;
    background-color: black;
    color: white;
    padding: 1rem 2rem;
    font-weight: 700;
    font-size: 12px;
    margin-top: 1rem;
    margin-bottom: 5rem;
}

/* ---Download---- */
.download{
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-bottom: 3rem;
}
.play-store{
    display: flex;
    gap: 0.5rem;
    background: black;
    color: white;
    padding: 0rem 2rem;
    border-radius: 20px;
}
.store-para{
    margin-block-end: 0em;
    font-size: 10px;
    margin-top: 1rem;
}
.google-store{
    margin-top: 1rem;
    font-size: 18px;
    margin-bottom: 1rem;
    margin-top: 0rem;
}


/* ---footer */
hr{
    color: #171616;
}
.footer{
    display: flex;
    justify-content: flex-end;
    gap: 20rem;
    margin-top: 2rem;
}
.services{
    display: flex;
    gap: 5rem;
}
.social-icons{
    display: flex;
    gap: 1rem;
    
}
li{
    list-style-type: none;
    color: black;
}
.socials{
    border: 1px solid #171616;
   padding: 0.5rem;
    border-radius: 100%;
}




/* ------------ media screens ----------- */

@media only screen and (max-width: 1200px) {
.powering-header{
font-size: 30px;
}
.powering-para{
    padding-right: 0rem;
}
.nfc{
    padding-left: 5rem;
}
h2{
    font-size: 50px;
}

.wonda-text{
    padding-left: 10rem;
    padding-right: 10rem;
}
.debit{
    padding-left: 2rem;
}
.nfc{
    padding-left: 4.5rem;
}
.nfc2{
    padding-left: 4.5rem;
}
.won{
    padding-right: 0rem;
    font-size: 30px;
}
.tap-text{
    padding-right: 4rem;
}
.text-wireless{
    padding-left: 10rem;
    padding-right: 10rem;
}
.img-tra{
    padding-left: 4.5rem;
}
.tap-text2{
    padding-right: 0rem;
}
.tap-transaction{
    font-size: 24px;
}
.more{
    font-size: 30px;
}
.tap-transaction2{
    font-size: 30px;
}
.info{
    font-size: 30px;
}
.totap{
    font-size: 30px;
}
}
@media only screen and (max-width: 1024px){
.powering-section{
    gap: 0;
}
.powering-header{
        margin-left: 2rem;
}
.powering-para{
    padding-left: 2rem;
    font-size: 18px;
}
.totap{
    width: fit-content;
}
.services{
    gap: 2rem;
}
.socials{
    padding: 1rem;
}
.tapto{
    padding-right: 0;
}
.debit{
    padding-top: 4rem;
}
.img-tra{
    padding-left: 0rem;
}
.nfc{   
    padding-left: 3rem;
    width: 40vw;
}
.nfc2{
    padding-left: 0;
    width: 40vw;
}
.img-tra{
    width: 40vw;
    padding-left: 3rem;
}
.tap-text{
    padding-right: 0;
}
.wonda-text{
    padding-left: 0;
    padding-right: 0;
}
.text-wireless{
    padding-left: 0;
    padding-right: 0;
}
.footer{
    gap: 8rem;
}
}
@media only screen and (max-width: 767px){
.menu-options{
    gap: 1rem;
}
.menu-header{
    gap: 5rem;
}
    .powering-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.powering-para{
    padding-left: 0;
}
.powering-header{
    margin-left: 0;
}
.scroll{
        display: none;
}
.transactions{
        display: flex;
    flex-direction: column;
    align-items: center;
}
.transactions3{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.text-wireless{
    padding-left: 3rem;
    padding-right: 3rem;
}
.more{
    font-size: 30px;
    margin-top: 5rem;
}
.info{
    font-size: 30px;
}
.wonda-text{
    padding-left: 0;
    padding-right: 0;
}
.debit{
    padding-top: 0;
}
.footer{
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}
body{
    padding: 1rem 2rem
}
}
@media only screen and (max-width: 600px){
.menu-options{
    display: none;
}
.powering-header{
    margin-left: 0;
    font-size: 30px;
}
.powering-section{
    margin-top: 10rem;
}
.debit{
    margin-top: 0;
    padding-left: 0;
}
.powering-para{
    padding-left: 0;
    font-size: 18px;
}
.info{
    font-size: 30px;
    text-align: center;
}
.wonda-text{
    padding-left: 1rem;
    padding-right: 1rem;
}
.img-tra{
    width: 70vw;
    margin-top: 3rem;
    padding-left: 0;
}
.nfc{
    width: 70vw;
    margin-top: 3rem;
    padding-left: 0;
}
.nfc2{
    width: 70vw;
    margin-top: 3rem;
    padding-left: 0;
}
.more{
    font-size: 30px;
}
.text-wireless{
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    font-size: 18px;
}

.footer{
  flex-direction: column;
    gap: 4rem;
    align-items: center;
}

body{
    padding: 1rem 3rem;
}
.download{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.hamburger{
    display: flex;
    width: 15vw;
    height: 2rem;
    justify-content: space-between;
    flex-flow: column nowrap;
    z-index: 10;
    margin-top: 2rem;
}
.burger{
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
}
}
@media only screen and (max-width: 400px){
.nfc{
    width: 60vw;
    margin-top: 3rem;
    padding-left: 0;
}
.powering-section{
    margin-top: 10rem;
}
.nfc2{
    width: 60vw;
    margin-top: 3rem;  
    padding-left: 0;
}
.img-tra{
    width: 60vw;
    padding-left: 0;
}
.black-card{
   justify-content: flex-start;
}
.powering-header{
    margin-left: 0;
    font-size: 20px;
    text-align: center;
}
.powering-para{
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
}
.won{
    font-size: 20px;
    text-align: center;
}
.tap-transaction{
    font-size: 18px;
}
.tap-text{
    font-size: 16px;
}
.debit{
    padding-top: 0;
    padding-left: 0;
}
.totap{
    font-size: 20px;
    width: max-content;
    text-align: center;
}
.tapto{
    font-size: 16px;
}
.more{
    font-size: 20px;
}
.text-wireless{
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    text-align: left;
}
.tap-transaction2{
    font-size: 20px;
    text-align: center;
}
.tap-text2{font-size: 16px;}
.img-tra{
    margin-top: 3rem;
}
.join{
    font-size: 14px;
    align-items: center;
    text-align: center;
}
.info{
    font-size: 20px;
}
.wonda-text{
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
}
.phone{
    width: 50vw;
}
body{
        padding: 1rem 2rem;
}
.footer{
    flex-direction: column;
    gap: 4rem;
    align-items: center;
}
iframe{
    height: 40vh;
}
.hamburger{
    display: flex;
    width: 15vw;
    height: 2rem;
    justify-content: space-between;
    flex-flow: column nowrap;
    z-index: 10;
    margin-top: 2rem;
}
.burger{
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    background-color: black;
    transform-origin: 1px;
    transition: all 0.3s linear;
}
}